.Banner__title{
  font-weight: 900;
  font-size: 1.5em;
  line-height: 37px;
  color: white;
}
.Banner__age{
  font-weight: 900;
  font-size: 2em;
  line-height: 49px;
  color: #034337;
}
.Banner__image{
  border-bottom-right-radius: 30px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position:right;
}
.capsule__title{
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-transform: initial;
}
.capsuleItem__title{
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  color: #12C185;
}
.capsuleItem__type{
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #555555;
  margin-left: 20px;
}
.number{
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
  .greeting{
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 32px;
    color: #FFC803;
    margin-bottom: 12px;
    margin-top: 12px;
  }
  .profile__username{
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 12px;
    margin-top: 12px;
    color: #034337;
  }
  .profile__personalInfo{
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
 
    margin-top: 12px;
    
    color: #93B73B;
  }
  .profile__info{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #555555;
  }
  .profile__state{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    margin-top: 30px;
    margin-bottom: 20px;
    /* identical to box height */
    text-align: center;
    
    color: #12C185;
  }
  .back__link{
      
color: #034337;
font-style: normal;
font-weight: bold;
font-size: 1.1em;
line-height: 24px;
  }

.capsule__ageRange{
    font-weight: 900;
font-size: 1.1em;
line-height: 24px;
color: white;
margin-right: 16px;
}

.capsule__bannerImg{
    border-radius: 18px;
    object-fit: cover;
 
    height: 100%;
    object-position: center;
    
}
.capsule__bannerTitle{
    font-weight: 900;
font-size: 1.4rem;
line-height: 32px;
color: #0d463b;
margin-left: auto;
margin-right:auto;
}
.capsule__bannerLevel{
    font-weight: 900;
font-size: 2em;
line-height: 32px;
color: white;
text-transform: capitalize;
}
.username__link{
    font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 15px;


text-decoration-line: underline;

color: #93B73B;
}
.login__welcome{
   color:#034337;
   font-style: normal;
   font-weight: 900;
   font-size: 20px;
   line-height: 24px;
  text-transform: 'uppercase';
}
.login__pageName{
  font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
   text-transform: 'uppercase';
    
    color: #89B237;
}
.login__text{
    font-style: normal;

    font-size: 20px;
    line-height: 24px;
}
.login__link{
    font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 17px;

/* identical to box height */
text-decoration-line: underline;

color: #12C185;
margin-bottom: 90px;
}
.form__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
}
.form__disclaimer{
  font-weight: normal;
font-size: 12px;
line-height: 15px;
margin: 15px;
}
.badge__title{
  font-style: normal;
font-weight: bold;
font-size: 10px;
line-height: 12px;
color: white;
}
.capsule__locked{
  pointer-events: none;
  opacity: 0.6;
  background: rgba(200, 54, 54, 0.5);
  filter: alpha(opacity=50);
  zoom: 1;
  -moz-opacity: 0.5;
  -khtml-opacity:0.5;
}
.activity__instruction{
  font-style: normal;
font-weight: normal;
font-size: 19px;
line-height: 23px;
margin-top: 12px;
margin-bottom: 16px;
}
.activity__instruction__hierarchy{
  font-style: normal;
font-weight: bold;
font-size: 19px;
line-height: 23px;
margin-top: 12px;
margin-bottom: 16px;
}
.welcome__text{
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 12px;
  margin-top: 8px;
}
.modal__question{
  font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 15px;
align-self:flex-start;
margin-top: 12px;
margin-bottom: 9px;
}
.modal__link{
  font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 15px;
margin-left: 30px;
margin-top: 9px;
text-decoration-line: underline;
cursor:pointer;
color: #12C185;
}
.victory__congratulation{
  font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 39px;
margin: 15px;
}
.victory__details{
  font-style: normal;
font-weight: normal;
font-size: 32px;
line-height: 39px;
margin: 40px;
}
.comic__img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.form__register{
  width: 90%;
 
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: auto;
}
.selected{
  border: 6px solid #12C185;
  z-index: 100;
  overflow: hidden;
}
.emotion__title{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 19px;
line-height: 23px;
}
.report__title{
  font-style: normal;
font-weight: 700;

line-height: 15px;
margin-top:30px;
}
.form__report{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
.password__link{
  font-weight: 700;
font-size: 12px;
line-height: 15px;
color: #12C185 !important;
margin-top: 15px!important;
}
.editor__margin{
  margin-top: 12px !important;
}
.editorClassName{
  background-color: white;
  overflow: scroll;
}
.selected__page{
  background-color:#12C185;
}
.css-eg0mwd-MuiSlider-thumb{
  border-radius:10px !important;
  height: 30px !important;

}
.css-1aznpnh-MuiSlider-root{
  color: #12C185 !important;
  height:15px !important;
}
.css-14pt78w-MuiSlider-rail{
  background-color: #fff !important;
  opacity: 1 !important;
}

.terms__title{
  text-align: center;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 32px !important;
color: #12C185;
font-family: 'Montserrat' !important;
}
.terms{
  font-weight: 400 !important;
  font-family: 'Montserrat' !important;
font-size: 14px !important;
line-height: 32px !important;
padding: 20px;
/* or 229% */

text-align: justify;

color: #000000;
}
.iframe__border{
  border: none;
  border-radius: 15px;
}


.date__picker{
  height: 30px;
  background-color: #12c185; 
  color: white;
  border-radius: 16px;
}

.date__picker div{
  height: 30px;
  background-color: #12c185; 
  color: white;
  border-radius: 16px;
}

.select{
  height: 30px;
  color: #12C185;
  border-radius: 16px;
  border: 1px solid #12C185;
}
.select div{
  height: 30px;
  color: #12C185;
  border-radius: 16px;
  border: 0px none;
}
 .select svg{
  color: #12C185; 
 }

 .terms__ul li{
  font-weight: 400 !important;
  font-family: 'Montserrat' !important;
font-size: 14px !important;
line-height: 24px !important;
padding: 20px;
margin-left: 50px;
text-align: left;
 }

 .player-wrapper {
  position: relative;
  width:100%;
  height: 100%;
 
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;

}

.floating__links{
  text-decoration:none;
  color:white;
  font-size: 12px;
}

.box1 {
  width: 45%;
  margin: 30px auto;
  border: 4px solid #93b73b;
  padding: 10px;
  text-align: center;
  font-weight: 400;
  
  font-size: 12px;
  position: relative;
}
.sb5:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid #93b73b;
  border-right: 10px solid transparent;
  border-top: 10px solid #93b73b;
  border-bottom: 10px solid transparent;
  right: -20px;
  top: 6px;
}

.sb5:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 7px solid #fff;
  border-right: 7px solid transparent;
  border-top: 7px solid #fff;
  border-bottom: 7px solid transparent;
  right: -11px;
  top: 10px;
}