@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');
*{
  margin: 0;
  padding: 0;

}
body{
  background-color: #E5E5E5;
  font-family: 'Montserrat', sans-serif;
 
}
html,body{
  height:100%;
}
.App{
  text-align: center;
  height: '100%';
 
  
}
.img {
  border-radius: 0%;
  -webkit-transition: -webkit-transform .8s ease-in-out;
          transition:         transform .8s ease-in-out;
}
.img:hover {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
.level {
 
  -webkit-transition: -webkit-transform .3s ease-in-out;
          transition:         transform .3s ease-in-out;
}
.level:hover {
  -webkit-transform: translateY(30px);
          transform: translateY(-30px);
}
.capsule {
  border-radius: 31px;
  -webkit-transition: -webkit-transform .5s ease-in-out;
          transition:         transform .5s ease-in-out;
}
.capsule:hover  {
  -webkit-transform: translateY(10px);
          transform: translateY(-10px);
}
.button__animation{
  -webkit-transition: -webkit-transform .10s ease-in-out;
          transition:         transform .10s ease-in-out;
}
.button__animation:focus{
  animation: wobble 1s 1;
}
.button__animation:hover{
  animation: wobble 1s 1;
}
#root{
  height:100%;
}
.button__animated[wobble='1'] {
  animation: wobble 1s 1;
}
.div__animated[bounce='1']{
  animation: bounce 3s 1;
}
@keyframes wobble {
  25% {
    transform: rotate(8deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
            40% {transform: translateY(-30px);} 
            60% {transform: translateY(-15px);}
}
.css-i4bv87-MuiSvgIcon-root{
  color: #12C185;
}
.css-1auycx3-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall{
  height:30px;
  align-content: center;
  border-radius:16px;
  color: #12C185; 
}
.css-1auycx3-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input{
  text-align:center; 
}